@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

:root {
    --settings-accent-lightness: 38%;
    --window-inner-height: 100vh;
}

@media (prefers-color-scheme: dark) {
    :root {
        --settings-accent-lightness: 70%;
    }
}

body {
    @apply bg-white dark:bg-gray-900 dark:text-white touch-manipulation;
    -webkit-tap-highlight-color: transparent;
    color-scheme: light dark;
}

.large-title {
    @apply text-6xl font-bold font-display;
}

.title {
    @apply text-4xl font-bold font-display;
}

.subtitle {
    @apply text-2xl font-display;
}

.heading {
    @apply text-2xl font-semibold font-display;
}

.subheading {
    @apply text-lg font-semibold font-display;
}

.subsubheading {
    @apply font-bold;
}

.caption {
    @apply text-sm;
}

.over {
    @apply tracking-wide font-semibold text-sm uppercase font-display;
}

.muted {
    @apply text-gray-500 dark:text-gray-400;
}

.lead {
    @apply text-xl font-display;
}

input[type="checkbox"], input[type="radio"] {
    @apply dark:bg-gray-800 dark:border-gray-500;
}

.markdown p {
    @apply mb-4;
}

.markdown h1 {
    @apply text-4xl font-bold font-display my-4;
}

.markdown h2 {
    @apply text-3xl font-bold font-display my-4;
}

.markdown h3 {
    @apply text-2xl font-bold font-display my-4;
}

.markdown h4 {
    @apply text-xl font-bold font-display my-4;
}

.markdown h5 {
    @apply text-lg font-bold font-display my-4;
}

.markdown h6 {
    @apply font-bold font-display my-4;
}

.markdown img {
    @apply mb-4;
}

.markdown blockquote {
    @apply rounded border-l-8 border-blue-500 bg-gray-50 dark:border-blue-400 dark:bg-gray-800 p-4 mb-4 w-fit;
}

.markdown blockquote > p:last-child {
    @apply mb-0;
}

.markdown ul {
    @apply list-disc list-inside mb-4;
}

.markdown li ul, .markdown li ol {
    @apply ml-6 mb-0;
}

.markdown ol {
    @apply list-decimal list-inside mb-4;
}

.markdown hr {
    @apply border-2 border-gray-300 dark:border-gray-600 mb-4;
}

.markdown code {
    @apply bg-gray-200 rounded dark:bg-gray-700 p-1;
}

.markdown pre {
    @apply rounded border border-gray-400 dark:border-gray-600 w-fit overflow-hidden mb-4;
}

.markdown pre > div {
    @apply p-2 block w-full;
}

.markdown pre code {
    @apply rounded-none p-0 bg-transparent;
}

.h-window {
    height: 100vh;
    height: var(--window-inner-height);
}
